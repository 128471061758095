
<template>
  <card-container>
    <v-toolbar color="#f1b434">
      <v-toolbar-title>Uppdrag</v-toolbar-title>
    </v-toolbar>
    <v-list three-line>
      <template v-for="(mission, i) in missions">
        <v-divider
          v-if="i > 0"
          :key="'div' + i"
        ></v-divider>
        <v-list-tile
          :key="i"
        >
          <v-list-tile-content>
            <v-list-tile-sub-title v-html="mission"></v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action >
            <v-checkbox
              color=#f1b434
              ripple
              v-model="bools[i]"
            ></v-checkbox>
          </v-list-tile-action>
        </v-list-tile>
      </template>
    </v-list>
  </card-container>
</template>

<script>
import cardContainer from '../components/CardContainer'
import { missions } from '@/MockedBackend'

export default {
  components: {
    cardContainer: cardContainer
  },
  data () {
    return {
      missions: missions,
      bools: [ false, false, false, false, false, false, false, false, false, false, false, false, false ]
    }
  },

  updated: function () {
    localStorage.completedMissions = JSON.stringify(this.bools)
  },

  beforeMount: function () {
    var cookies = localStorage.completedMissions
    if (cookies !== undefined) {
      this.bools = JSON.parse(cookies)
    }
  }
}
</script>

// class Warning {
//   constructor (nationIndex, time, text) {
//     this.index = nationIndex
//     this.time = time
//     this.text = text
//   }
// }

// var warnings = []

// function GetWarnings () {
//   return warnings
// }

// function AddWarning (nationIndex, message, time) {
//   warnings.push(new Warning(nationIndex, time, message))
// }

function getScedule (classString) {
  var scedules = {
    F1: [[13], [3], [3], [5], [10], [12], [4], [1], [7]],
    F2: [[13], [12], [3], [3], [4], [10], [1], [5], [7]],
    F3: [[13], [1], [12], [3], [3], [4], [10], [2], [7]],
    F4: [[13], [10], [1], [12], [3], [3], [5], [7], [7]],
    F5: [[13], [5], [10], [1], [7], [3], [3], [12], [7]],
    F6: [[13], [3], [5], [10], [1], [7], [2], [3], [7]]
  }
  return scedules[classString]
}

// var usernames = [ ]

// function GetUsernames () {
//   return usernames
// }
// function AddUsername (username) {
//   if (usernames.includes(username)) {
//     return false
//   } else {
//     usernames.push(username)
//     return true
//   }
// }

var missions = [
  'Håll en ritual',
  'Sjung en psalm med minst ett bord',
  'Ta en fet bild med något gudomligt',
  'Ta valfri enhet med Thor',
  'Kom på och beställ en egen gudomlig drink',
  'Gör en god gärning för någon du inte känner',
  'Ta en selfie med domkyrkan',
  'Rulla en sten uppför en backe',
  'Spå någon i deras händer',
  'Utför en exorcism',
  'Hitta något heligt/en totem',
  'Fånga en ängel',
  'Drick heligt vatten'
]

// var missions = [
//   '--redacted--',
//   '--redacted--',
//   '--redacted--',
//   '--redacted--',
//   '--redacted--',
//   '--redacted--',
//   '--redacted--',
//   '--redacted--',
//   '--redacted--',
//   '--redacted--',
//   '--redacted--',
//   '--redacted--'
// ]

const nationPermastatus = [
  { // GH
    open: false,
    message: 'stängt'
  },
  { // Gotland
    open: true
  },
  { // Göteborgs
    open: true
    // message: 'undvik'
  },
  { // Kalmars
    open: true
  },
  { // Norrlands
    open: true
  },
  { // Smålands
    open: true
  },
  { // Snerikes
    open: false,
    message: 'stängt'
  },
  { // Stocken
    open: true
  },
  { // Uplands
    open: false,
    message: 'stängt'
  },
  { // V-Dala
    open: false,
    message: 'stängt'
  },
  { // VG
    open: true
  },
  { // Värmlands
    open: true
  },
  { // ÖG
    open: true
    // message: 'stängt'
  },
  { // Skånes
    open: true
  }
]
export { getScedule, missions, nationPermastatus }
